import AboutUsHeroSection from "../../component/organism/AboutUs/HeroSection";
import DiscoverWorksSection from "../../component/organism/AboutUs/DiscoverWorks";
import AboutUsStatsSection from "../../component/organism/AboutUs/StatsSection";
import OurTeamSection from "../../component/organism/AboutUs/OurTeam";
import ContactUsSection from "../../component/organism/Home/ContactUs";
import HiringSection from "../../component/organism/AboutUs/HiringSection";
import {useEffect} from "react";

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo({top:0, left:0, behavior: "smooth"})
    }, []);
    return (
        <div>
            <AboutUsHeroSection/>
            <DiscoverWorksSection/>
            <AboutUsStatsSection/>
            {/*<OurTeamSection/>*/}
            <HiringSection />
            <ContactUsSection scrollBtnPosition={"-500vh"}/>
        </div>
    );
};

export default AboutUs;